import React from 'react'
import "./Service.css"
 
const ServiceBody1=()=>{
return(<>

    <section id="head-section-our-service-page">
        <div className="head-text-our-service-page">
           
        </div>
    </section>

    {/* <section class="who-we-are">
        <h2>IT support and solution</h2>
        <p>We are offering all kinds of solution services which helps to grow yourbusiness</p>
    </section> */}

</>)

}
export default ServiceBody1;