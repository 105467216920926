import React from 'react'
import "./About.css"
import AboutBody from './About-body'


const About=()=>{
return(<>
<AboutBody/>
</>)

}
export default About;