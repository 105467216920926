import React from 'react'
import PortfolioBody1 from './PortfolioBody';
import Footer from "../footer/Footer"

const Portfolio=()=>{
return(<>
<PortfolioBody1/>
</>);

}
export default Portfolio;