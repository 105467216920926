import React from 'react'
import "./Service.css"
import OurServices from '../home/OurServices/OurServices';
import ServiceBody1 from './ServiceBody';
import Footer from "../footer/Footer"
import ServiceBody2 from './ServiceBody2';


const Service=()=>{
return (<>
<ServiceBody1/>
<ServiceBody2/>
<OurServices/>

</>)

}
export default Service;